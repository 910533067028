<template>
  <div>
    <div v-if="magicLinkSent">
      <BaseCard class="text-center">
        <h1 class="text-3xl">
          Check your inbox
        </h1>
        <Mailbox class="w-56 text-center mx-auto my-6"/>
        <p class="text-lg">
          We’ve emailed a link to <span class="font-semibold text-xl">{{ formResponses.email }}</span>
        </p>
      </BaseCard>
    </div>
    <div v-else>
      <h1 class="text-2xl md:text-3xl xl:text-4xl">
        Sign in
      </h1>

      <form
        class="mt-8"
        novalidate
        @submit.prevent
      >
        <label class="block mt-6 relative">
          <span class="text-gray-800">Email address</span>
          <input
            v-model="$v.formResponses.email.$model"
            type="email"
            class="form-input mt-1 block w-full"
            placeholder="Email address"
          >
        </label>
        <ErrorsInline v-if="errorMessage">
          {{ errorMessage }}
        </ErrorsInline>

        <section>
          <BaseButton
            class="mt-8"
            :loading="isProcessing"
            :disabled="!canSubmitEmail"
            @click="submit"
          >
            Send magic link
          </BaseButton>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'
import Mailbox from '@components/Vectors/Mailbox'

import candidateTokens from '@api/candidateTokens'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    ErrorsInline,
    Mailbox
  },

  mixins: [validationMixin],

  data() {
    return {
      isProcessing: false,
      errorMessage: null,
      magicLinkSent: false,
      formResponses: {
        email: null
      }
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    canSubmitEmail() {
      return !this.$v.formResponses.$invalid
    }
  },

  validations: {
    formResponses: {
      email: {
        required,
        email
      }
    }
  },

  methods: {
    submit() {
      this.isProcessing = true
      this.errorMessage = null

      candidateTokens.candidateReminder(this.formResponses.email)
        .then(response => {
          if (response.data.success) {
            this.magicLinkSent = true
            this.isProcessing = false
            return
          }
          throw new Error('Response did not contain success')
        })
        .catch(error => {
          this.isProcessing = false
          this.errorMessage = 'Email not registered'
          if (error.response && error.response.data && error.response.data.errorMessage) {
            this.errorMessage = error.response.data.errorMessage
            return
          }
          throw error
        })
    }
  }
}
</script>
