<template>
  <div class="w-full max-w-lg relative mx-auto py-8 sm:py-16 px-4">
    <CandidateSignIn />
  </div>
</template>

<script>
import CandidateSignIn from '@components/Auth/CandidateSignIn'

export default {
  components: {
    CandidateSignIn
  }
}
</script>
